import React from 'react';
import { Plotly } from '../../__DEPRECATED__modules/dataviz';
import { PlotArgs } from '@hum/icm-app/src/state';
import { CompanyChart, Content, TypeOfContent } from '@hum/types';
import { Icon, Splash, TextType, Text } from '@hum/ui-library';

const CHART_MARGIN = 80;

export const Chart: React.FC<{ plotArgs: PlotArgs; key: number }> = ({
  plotArgs,
  key,
}) => {
  return (
    <Plotly
      key={key}
      layout={JSON.parse(JSON.stringify(plotArgs.layout))}
      data={plotArgs.data}
      responsive
      margin={{
        b: CHART_MARGIN,
        r: CHART_MARGIN,
        t: CHART_MARGIN,
        l: CHART_MARGIN,
      }}
    />
  );
};

export const ProfileCharts = ({ charts }: { charts: CompanyChart[] }) => {
  // Generate an array of charts that only renders chrats of type chart (i.e. exclude insights)
  const chartContent = charts.reduce((acc, chartWrapper) => {
    if (chartWrapper?.content?.length) {
      return [...acc, ...chartWrapper.content];
    }
    return acc as Content[];
  }, [] as Content[]);

  return (
    <div className="w-full max-w-2xl">
      {chartContent.map((chart: Content, i) => (
        <Chart plotArgs={chart.value as PlotArgs} key={i} />
      ))}
    </div>
  );
};

export const ChartList: React.FC<{
  charts: CompanyChart[];
  wideEmptyChart?: boolean;
}> = ({ charts }) => {
  return (
    <div>
      {charts.map((chart, index) => (
        <div className="flex items-center gap-4 mb-10" key={index}>
          {chart.content.map((c) =>
            c.type == TypeOfContent.Chart ? (
              <div className="w-full">
                <Text type={TextType.HEADING_L} className="pb-6">
                  {c.title}
                </Text>
                <Chart
                  plotArgs={c.value as PlotArgs}
                  data-testid={c.title}
                  key={chart.id}
                />
              </div>
            ) : (
              <div key={chart.id}>
                <Text type={TextType.HEADING_L}>{c.title}</Text>
                <Text type={TextType.BODY_M} className="mt-4">
                  {c.value}
                </Text>
              </div>
            )
          )}
        </div>
      ))}
      {!charts.length && (
        <div className="flex h-full">
          <div className="flex flex-col items-center justify-around w-full">
            <Splash
              graphic={
                <Icon.InvestorSignupStepThree className="max-w-[200px] mx-auto" />
              }
              title="Connect data to see insights"
              subtitle="Connect your accounting software in the Data Sharing tab or upload Income Statement and Balance sheet data in your data room. Some additional insights are also available when you connect to your bank and/or payment processor data as well."
            >
              <Text type={TextType.BODY_S} subdued className="max-w-md mx-auto">
                Note: Insights can take some time to generate once your data is
                connected, thank you for your patience
              </Text>
            </Splash>
          </div>
        </div>
      )}
    </div>
  );
};
