import React, { useEffect, useRef } from 'react';
import Plotly from 'plotly.js';
import { ChartBaseType } from '@hum/types';

export const ChartBase = ({
  testId,
  data,
  layout,
  config,
  otherMarkers,
}: ChartBaseType) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loadCharts = async () => {
      const chart = await Plotly.newPlot(
        chartRef.current!,
        data,
        layout,
        config
      );

      if (otherMarkers) {
        await Plotly.addTraces(chart, {
          ...otherMarkers,
          type: 'scatter',
          mode: 'markers',
        });
      }
    };
    loadCharts();
  }, [data, layout, config]);

  return <div className="m-3" data-testid={testId} ref={chartRef} />;
};
