import React from 'react';
import {
  Button,
  ButtonType,
  Checkbox,
  Icon,
  Modal,
  ModalFooter,
  Scrollable,
} from '@hum/ui-library';
import { TextInput } from '@hum/legacy-ui';

export const BulkDownloadModal = ({
  isOpen,
  onClose,
  fileFilter,
  setFileFilter,
  fileList,
  handleDownloadSelectedClicked,
  selectAll,
  selectedCustomerFiles,
  selectedSupportedFinancialDocuments,
  handleSelectAllChange,
  handleCustomerFileChange,
  handleSupportedFinancialDocumentsChange,
}) => {
  const noneSelected =
    !Object.values(selectedCustomerFiles).some(Boolean) &&
    !Object.values(selectedSupportedFinancialDocuments).some(Boolean);

  return (
    <Modal
      title="Download financial files"
      isOpen={isOpen}
      description="Please select which files you'd like to download"
      withTransitions
      wide
    >
      <div className="px-8 pb-2.5 mt-[-22px]">
        <TextInput
          className="mb-2.5"
          icon={<Icon.Close onClick={() => setFileFilter('')} />}
          onValueChange={(text: string) => setFileFilter(text)}
          value={fileFilter}
          placeholder="Search"
        />
        <Checkbox
          className="pb-2.5 border-borderColor border-b-2 mb-2.5"
          name="selectAll"
          label="Select All"
          value={selectAll}
          onChange={handleSelectAllChange}
        />
        <Scrollable className="h-96">
          {fileList &&
            fileList.customerFiles
              .filter((file) => file.name.match(fileFilter))
              .map((file) => (
                <Checkbox
                  key={file.id}
                  name="customerFiles"
                  className="mb-2.5 text-ellipse"
                  label={`${file.name}.${file.ext}`}
                  onChange={handleCustomerFileChange(file.id)}
                  value={selectedCustomerFiles[file.id]}
                />
              ))}
          {fileList &&
            fileList.supportedFinancialDocuments
              .filter((file) => file.connectorType.match(fileFilter))
              .map((file) => {
                const key = `${file.connectorType}:${file.docType}:${file.connectorSource}`;
                return (
                  <Checkbox
                    name="supportedFinancialDocuments"
                    key={key}
                    className="mb-2.5 text-ellipse"
                    label={`${file.connectorType} ${file.docType} (${file.connectorSource})`}
                    onChange={handleSupportedFinancialDocumentsChange(key)}
                    value={selectedSupportedFinancialDocuments[key]}
                  />
                );
              })}
        </Scrollable>
      </div>
      <ModalFooter>
        <Button type={ButtonType.QUIET} onClick={onClose}>
          Cancel
        </Button>
        <Button
          type={ButtonType.DESTRUCTIVE}
          disabled={noneSelected}
          onClick={() => {
            handleDownloadSelectedClicked();
            onClose();
          }}
        >
          Download
        </Button>
      </ModalFooter>
    </Modal>
  );
};
